import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: {
      name: 'Wait',
    },
  },
  //二维码支付页
  // {
  //   path: '/initiate',
  //   name: 'Initiate',
  //   meta: { requireAuth: true },
  //   component: () =>
  //     import(/* webpackChunkName: "Initiate" */ '@/pages/Initiate/index.vue'),
  // },
  //二维码支付页
  {
    path: '/payCode',
    name: 'PayCode',
    meta: { requireAuth: true },
    component: () =>
      import(/* webpackChunkName: "PayCode" */ '@/pages/PayCode/index.vue'),
  },
  //支付页
  {
    path: '/pay',
    name: 'Pay',
    meta: { requireAuth: true },
    component: () =>
      import(/* webpackChunkName: "Pay" */ '@/pages/Pay/index.vue'),
  },
  {
    path: '/new-pay',
    name: 'NewPay',
    meta: { requireAuth: true },
    component: () =>
      import(/* webpackChunkName: "NewPay" */ '@/pages/Pay/newPay.vue'),
  },
  //支付结果 - 成功
  {
    path: '/successResult',
    name: 'successResult',
    meta: { requireAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "successResult" */ '@/pages/PayResult/success.vue'
      ),
  },
  //支付结果 - 失败
  {
    path: '/failResult',
    name: 'failResult',
    meta: { requireAuth: true },
    component: () =>
      import(/* webpackChunkName: "failResult" */ '@/pages/PayResult/fail.vue'),
  },
  //支付结果 - 超时
  {
    path: '/timeOutResult',
    name: 'timeOutResult',
    meta: { requireAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "timeOutResult" */ '@/pages/PayResult/timeOut.vue'
      ),
  },
  //等待页
  {
    path: '/wait',
    name: 'Wait',
    meta: { requireAuth: true },
    component: () =>
      import(/* webpackChunkName: "Wait" */ '@/pages/Wait/index.vue'),
  },
  //支付宝页面
  {
    path: '/alipay',
    name: 'AliPay',
    meta: { requireAuth: true },
    component: () =>
      import(/* webpackChunkName: "AliPay" */ '@/pages/AliPay/index.vue'),
  },
  //抖音页面
  {
    path: '/douyin',
    name: 'Douyin',
    meta: { requireAuth: true },
    component: () =>
      import(/* webpackChunkName: "Douyin" */ '@/pages/Douyin/index.vue'),
  },
  //京东登录页面
  {
    path: '/jdLogin',
    name: 'JDLogin',
    meta: { requireAuth: true },
    component: () =>
      import(/* webpackChunkName: "JDLogin" */ '@/pages/JDLogin/index.vue'),
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes,
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err)
}

export default router
